@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* #08415C
#0782DA */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

.navigation-content li {
  margin-left: 30px;
}

.navigation-content li:last-child {
  margin-left: 10px;
}

.navigation-content a {
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}

.navigation .active {
  color: #08415C;
  font-weight: 700;
}

.navigation .btn-primary-v2,
.navigation .btn-secondary-v2 {
  padding: 8px 21px;
}

.slick-slider {
  height: calc(100vh - 96px);
}

.slick-next {
  right: 25px !important;
}

.slick-prev {
  left: 25px !important;
}

.slick-dots {
  bottom: 65px !important;
}

.slick-dots li button:before {
  font-size: 16px !important;
  color: #ffffff !important;
  opacity: .5 !important;
}

.slick-dots li.slick-active button:before {
  color: #08415C !important;
  opacity: 1 !important;
  font-size: 24px !important;
}

.slick-prev,
.slick-next {
  width: 50px !important;
  height: 50px !important;
  z-index: 12 !important;
}

.slick-slider img {
  height: calc(100vh - 140px);
}

.header-content {
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 123;
}

.btn-primary-v2,
.btn-secondary-v2 {
  border-radius: 0;
  font-weight: 500;
  padding: 10px 25px;
}

.btn-primary-v2 {
  background-color: #fff;
  border: 1px solid #08415C;
  color: #08415C;
}

.btn-secondary-v2 {
  background-color: #08415C;
  color: #fff;
  border: 1px solid #08415C;
}

.btn-secondary-v3 {
  background-color: #ffffff;
  color: #08415C;
  font-weight: 500;
  border-radius: 0;
  font-size: 18px;
  width: 250px;
  padding: 12px 0;
}

.btn-secondary-v3:hover {
  background-color: #e6e6e6;
  color: #08415C;
}

.btn-secondary-v3:active,
.btn-secondary-v3:visited,
.btn-secondary-v3:focus-visible,
.btn-secondary-v3:focus {
  background-color: #fff !important;
  border: 1px solid #08415C !important;
  color: #08415C !important;
}

.btn-primary-v2:hover {
  background-color: #08415C;
  color: #fff;
  border: 1px solid #08415C;
}

.btn-primary-v2:active,
.btn-primary-v2:visited,
.btn-primary-v2:focus-visible,
.btn-primary-v2:focus {
  background-color: #fff !important;
  border: 1px solid #08415C !important;
  color: #08415C !important;
}

.btn-secondary-v2:active,
.btn-secondary-v2:visited,
.btn-secondary-v2:focus-visible,
.btn-secondary-v2:focus {
  background-color: #08415C !important;
  border: 1px solid #08415C !important;
  color: #fff !important;
}

.btn-secondary-v2:hover {
  background-color: #0A0F20;
  color: #fff;
}

.experience-box {
  background-color: #08415C;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 0 20px;
}

.experience-box:before {
  position: absolute;
  content: '';
  width: 290px;
  height: 290px;
  border-radius: 50%;
  left: -55px;
  top: -55px;
  border: 20px solid rgb(255 255 255 / 50%);
}

.experience-box h2 {
  font-weight: 700;
  font-size: 46px;
}

.experience-box h4 {
  font-size: 16px;
  font-weight: 400;
}

.component-subtitle {
  color: #08415C;
  font-size: 16px;
  letter-spacing: .5px;
  font-weight: 600;
}

.about-us-icons h4 {
  font-size: 1.3rem !important;
  font-weight: 700;
}

.about-us-icon {
  margin-top: 25px;
  transition: all .4s ease;
}

.about-us-icon:hover {
  margin-left: 20px;
}

.about-us-icon:last-child:hover {
  margin-left: -12px !important;
}

.component-title {
  font-size: 42px;
  font-weight: 700;
}

.component-paragraph {
  color: #676767;
}

.about-us-icons .icons-content {
  background-color: rgba(7, 130, 218, 0.13);
  width: fit-content;
  border-radius: 50%;
  padding: 20px;
}

.online-banking-container {
  position: relative;
  color: white;
  overflow: hidden;
}

.online-banking-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/img/service-bg.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.05;
  z-index: -1;
}

.services-heading {
  color: #ff0000;
  font-weight: bold;
  margin-bottom: 20px;
  z-index: 1;
}

.main-heading {
  font-size: 28px;
  margin-bottom: 40px;
  z-index: 1;
}

.service-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 30px;
  /* border-radius: 8px; */
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: #333;
  z-index: 1;
}

.service-item:hover {
  transform: scale(1.05);
}

.service-item h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

.service-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.service-item ul li {
  margin-bottom: 8px;
  color: #676767;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.service-item i {
  font-size: 6px;
  margin-right: 10px;
}

.red-box {
  background-color: #ff0000;
}

.platforms-content {
  background-color: #F6F6F6;
}

.platforms-content.about {
  background-color: #fff;
}

.advantages-cta-content {
  background-color: #08415C;
  padding: 50px 0;
}

.advantages-cta-content .component-title {
  font-size: 30px;
  line-height: 42px;
}

.mobile-app {
  padding: 5px 20px;
  background-color: #000;
  border: none;
  color: #fff;
  transition: all .2s ease;
  width: 210px;
}

.mobile-app-subtitle {
  font-size: 15px;
}

.mobile-app-title {
  font-size: 20px;
  font-weight: 700;
}

.mobile-app:hover {
  background-color: #08415C;
}

.homepage-contact-container {
  background-color: #F7F7F7;
  padding: 100px 0;
}

.homepage-contact-container .form-control {
  border-radius: 2px;
  padding: 14px 20px;
}

.footer-content {
  background-color: #0A0F20;
  color: #fff;
  padding: 75px 0;
}

.subpage-about-container {
  background-size: cover;
  background-position: center;
  height: 400px;
  /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.investment-card {
  position: relative;
  height: 400px;
  color: white;
  overflow: hidden;
  padding: 30px;
}

.investment-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(237, 111, 28, 0) 0%, rgba(0, 0, 0, 0.30) 57%, rgba(0, 0, 0, 0.40) 100%);
  z-index: 1;
}

.investment-card h5,
.investment-card p {
  position: relative;
  z-index: 2;
}

.investment-card h5 {
  font-size: 42px;
  transition: all .4s ease;
}

/* Yeni eklenen kısım */
.investment-card p {
  max-height: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: max-height 0.4s ease, opacity 0.4s ease, transform 0.4s ease;
}

.investment-card:hover p {
  max-height: 300px;
  /* İçeriğe bağlı olarak ayarlanabilir */
  opacity: 1;
  transform: translateY(0);
}

.investment-card:hover h5 {
  font-size: 36px;
}

@media (max-width: 768px) {
  .investment-card p {
    max-height: none;
    opacity: 1;
    transform: translateY(0);
    font-size: 14px;
  }

  .investment-card h5 {
    font-size: 24px;
  }
}

.subpage-about-content {
  color: white;
  text-align: center;
  z-index: 2;
}

.subpage-about-content h1 {
  font-size: 3.5em;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.subpage-about-content p {
  font-size: 1em;
}

.why-choose-img {
  background-color: #08415C;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.why-choose-card p {
  color: #676767;
  letter-spacing: .3px;
}

.why-choose-container {
  background-color: #F6F6F6;
  padding: 75px 0;
}

.vision-content {
  background-color: #F6F6F6;
}

.trading-platforms-component-content {
  padding: 50px 0 100px 0;
}

.trading-platforms-component-content .item-icon {
  background-color: rgba(8, 65, 92, 0.12);
  border-radius: 50%;
  padding: 10px;
}

.contact-card {
  background-color: #F7F7F7;
  text-align: center;
  padding: 65px;
  position: relative;
}

.contact-card-content {
  padding: 100px 0;
}

.contact-card .contact-card-icon {
  position: absolute;
  top: -35px;
  border-radius: 50%;
  left: 50%;
  transform: translate(-50%);
  background-color: #08415C;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaigns-content .card {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-width: 350px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.campaigns-content .card-image-wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.campaigns-content .card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Görselin alanı tamamen kaplaması için */
}

.campaigns-content .card-content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: rgba(0, 0, 0, 0.4);
  /* Arkaplanı karartmak için */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.campaigns-content .card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 1.2;
  color: #fff;
  width: 75%;
}

.banner-slide {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 600px;
  content: "";
  background-image: url("./assets/img/homepage/b-Home.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-overlay h3 {
  text-align: center;
  color: white;
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 1.3;
}

.dict-btn {
  width: 35px;
  height: 35px;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 18px;
}

.primary-v2-color {
  background-color: #0782DA;
  border-radius: 50%;
  color: #fff;
}

#dictionaryModal .modal-body .accordion {
  height: 500px;
  overflow: auto;
}

.banner-overlay .btn-secondary-v2 {
  font-size: 1.2rem !important;
}

.mobile-navigation {
  font-size: 20px;
  font-weight: 500;
}

.announcement-bar-content {
  background-color: #dfeffa;
}

.accordion-item,
.accordion-button {
  border-radius: 0 !important;
}

.accordion-button {
  font-size: 19px;
  font-weight: 600;
}

.accordion-button,
.accordion-body {
  padding: 20px 35px;
}

.accordion-body {
  font-weight: 500;
}

.accordion-button:not(.collapsed) {
  background-color: #DFEFFA !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .banner-overlay h3 {
    font-size: 2rem;
  }

  .banner-image {
    height: 350px;
  }

  .banner-overlay .btn-secondary-v2 {
    font-size: 1rem !important;
  }

  .about-us-image {
    width: 100%;
  }

  .experience-box {
    display: none;
  }

  .component-subtitle {
    margin-top: 20px;
    font-size: 18px;
  }

  .mobile-text-center {
    text-align: center;
  }

  .platform-mockup-image {
    text-align: center !important;
    margin-bottom: 20px;
  }

  .platform-text-area {
    width: 100% !important;
  }

  .platform-app-icons {
    justify-content: center;
  }
}

.account-types-card {
  position: relative;
  height: 400px;
  color: white;
  padding: 35px;
  background-position: center;
}

.account-types-card p {
  font-size: 17px;
}

.account-types-card h5 {
  font-size: 40px;
  transition: all .4s ease;
}

@media (max-width: 768px) {
  .account-types-card {
    padding: 25px;
  }

  .account-types-card p {
    max-height: none;
    opacity: 1;
    transform: translateY(0);
    font-size: 15px;
  }

  .account-types-card h5 {
    font-size: 32px;
  }
}


.academy-card {
  position: relative;
  color: white;
  padding: 125px 50px 65px;
  background-position: center;
  transition: all .3s ease;
}

.academy-card .modal-content {
  border-radius: 0;
}

.academy-card p {
  font-size: 17px;
}

.academy-card h5 {
  font-size: 40px;
  transition: all .4s ease;
}

.academy-card:hover {
  scale: 1.03;
}

.online-banking-container .component-title {
  width: 50%;
}

.promotions-card {
  border: 1px solid #efefef;
  transition: all .4s ease;
}

.promotions-card:hover {
  scale: 1.03;
}

.promotions-card h3 {
  font-weight: 600;
  font-size: 30px;
}

.promotions-card p {
  color: #676767;
}

.promotions-card-image {
  background-size: cover;
  content: "";
  width: 100%;
  height: 225px;
  background-position: center;
}

.footer-navigation-list li {
  margin-top: 10px;
  color: #e9e9e9;
}

.footer-navigation-list a {
  color: #e9e9e9;
  text-decoration: none;
}

.footer-title {
  font-size: 22px;
}

@media (max-width: 768px) {
  .academy-card {
    padding: 25px;
  }

  .academy-card p {
    max-height: none;
    opacity: 1;
    transform: translateY(0);
    font-size: 15px;
  }

  .academy-card h5 {
    font-size: 32px;
  }

  .component-title {
    font-size: 28px;
  }

  .online-banking-container .component-title {
    width: 100%;
  }

  .platform-text-area {
    text-align: center;
  }

  .homepage-contact-container {
    padding: 50px 0;
  }

  .about-us2-content-img {
    display: none;
  }

  .subpage-about-content h1 {
    font-size: 2.3em;
  }

  .subpage-about-container {
    height: 275px;
  }
}

.modal-body ::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
.modal-body ::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #EBEBEB;
}

.modal-body ::-webkit-scrollbar-track:hover {
  background-color: #DEDEDE;
}

.modal-body ::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

.modal-body ::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #000000;
}

.modal-body ::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

.modal-body ::-webkit-scrollbar-thumb:active {
  background-color: #000000;
}